import request from '@/utils/request'

// 代理创建和更新
export function AgentCreateOrUpdate(data) {
  return request({
    url: '/api/services/app/Agent/CreateOrUpdate',
    method: 'post',
    data
  })
}

// 代理列表
export function GetAgentList(data) {
    return request({
      url: '/api/services/app/Agent/GetPagedList',
      method: 'get',
      params: data
    })
}

// 代理详情
export function GetAgentInfo(data) {
  return request({
    url: '/api/services/app/Agent/GetInfo',
    method: 'get',
    params: data
  })
}

export function remove(data) {
  return request({
    url: '/api/services/app/Agent/Delete',
    method: 'delete',
    params: data
  })
}

// 代理计算统计信息
export function ComputeAgentStatisticsInfo(data) {
    return request({
      url: '/api/services/app/Agent/ComputeStatisticsInfo',
      method: 'post',
      data
    })
}

// 代理更新统计信息，如果不传id表示更新所有
export function UpdateAgentStatistics(data) {
    return request({
      url: '/api/services/app/Agent/UpdateStatistics',
      method: 'put',
      data
    })
}

// 获取所有代理
export function GetAgentAll(data) {
    return request({
      url: '/api/services/app/Agent/GetAll',
      method: 'get',
      params: data
    })
}

// 获取下级代理
export function GetAgentChildren(data) {
    return request({
      url: '/api/services/app/Agent/GetChildren',
      method: 'get',
      params: data
    })
}

// 代理数据概要
export function QueryAgentSummary(data) {
    return request({
      url: '/api/services/app/AgentData/QuerySummary',
      method: 'post',
      data
    })
}