import request from '@/utils/request'

export function create(data) {
  return request({
    url: '/api/services/app/Role/Create',
    method: 'post',
    data
  })
}

export function getAll(data) {
  return request({
    url: '/api/services/app/Role/GetPagedList',
    method: 'get',
    params: data
  })
}

export function getRoles(data) {
  return request({
    url: '/api/services/app/Role/GetAll',
    method: 'get',
    params: data
  })
}

export function getAllPermissions(data) {
  return request({
    url: '/api/services/app/Role/GetAllPermissions',
    method: 'get',
    params: data
  })
}

export function update(data) {
  return request({
    url: '/api/services/app/Role/Update',
    method: 'put',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/services/app/Role/Get',
    method: 'get',
    params: data
  })
}

export function remove(data) {
  return request({
    url: '/api/services/app/Role/Delete',
    method: 'delete',
    params: data
  })
}

export function removeMuliple(data) {
  return request({
    url: '/api/services/app/Role/Deletes',
    method: 'delete',
    params: data
  })
}
