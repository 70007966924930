import request from '@/utils/request'

// 获取所有商家
export function GetAllMerchants(data) {
    return request({
      url: '/api/services/app/Merchant/GetAll',
      method: 'get',
      params: data
    })
}

// 获取附近门店
export function GetNearbyMerchants(data) {
    return request({
      url: '/api/services/app/Merchant/GetNearbyMerchants',
      method: 'get',
      params: data
    })
}

// 创建或者更新
export function CreateOrUpdate(data) {
    return request({
        url: '/api/services/app/Merchant/CreateOrUpdate',
        method: 'post',
        data
    })
}

// 获取商户列表
export function GetMerchantList(data) {
    return request({
      url: '/api/services/app/Merchant/GetPagedList',
      method: 'get',
      params: data
    })
}

// 获取商家信息
export function GetMerchantDetail(data) {
    return request({
      url: '/api/services/app/Merchant/GetInfo',
      method: 'get',
      params: data
    })
}

// 营业额统计
export function QueryTurnoverStatistics(data) {
    return request({
        url: '/api/services/app/Merchant/QueryTurnoverStatistics',
        method: 'post',
        data
    })
}

// 更新统计
export function ComputeStatisticsInfo(data) {
    return request({
        url: '/api/services/app/Merchant/ComputeStatisticsInfo',
        method: 'post',
        data
    })
}

// 计算商家统计数据
export function UpdateStatistics(data) {
    return request({
      url: '/api/services/app/Merchant/UpdateStatistics',
      method: 'put',
      data
    })
}

// 删除商家
export function MerchantDelete(data) {
    return request({
      url: '/api/services/app/Merchant/Delete',
      method: 'delete',
      params: data
    })
}

// 打开关闭商家
export function OpenOrClose(data) {
    return request({
        url: '/api/services/app/Merchant/OpenOrClose',
        method: 'post',
        data
    })
}

// 设置商家代理
export function SetAgent(data) {
    return request({
        url: '/api/services/app/Merchant/SetAgent',
        method: 'post',
        data
    })
}

// 获取商户费用设置
export function GetFreeSetInfo(data) {
    return request({
      url: '/api/services/app/Merchant/GetFreeSetInfo',
      method: 'get',
      params: data
    })
}

// 商家费用设置
export function SetFree(data) {
    return request({
        url: '/api/services/app/Merchant/SetFree',
        method: 'post',
        data
    })
}

// 获取商户档案信息
export function GetArchiveInfo(data) {
    return request({
      url: '/api/services/app/Merchant/GetArchiveInfo',
      method: 'get',
      params: data
    })
}

// 修改商户档案
export function UpdateArchive(data) {
    return request({
      url: '/api/services/app/Merchant/UpdateArchive',
      method: 'put',
      data
    })
}

// 批量删除商家投放位置
export function PositionDeletes(data) {
    return request({
      url: '/api/services/app/MerchantPlacementPosition/Deletes',
      method: 'delete',
      params: data
    })
}

// 删除商家投放位置（单个）
export function PositionDelete(data) {
    return request({
      url: '/api/services/app/MerchantPlacementPosition/Delete',
      method: 'delete',
      params: data
    })
}

// 创建商家投放位置
export function PositionCreate(data) {
    return request({
        url: '/api/services/app/MerchantPlacementPosition/Create',
        method: 'post',
        data
    })
}

// 获取投放位置信息
export function PositionDetail(data) {
    return request({
      url: '/api/services/app/MerchantPlacementPosition/Get',
      method: 'get',
      params: data
    })
}

// 获取投放位置列表信息
export function PositionList(data) {
    return request({
      url: '/api/services/app/MerchantPlacementPosition/GetPagedList',
      method: 'get',
      params: data
    })
}

// 修改商家投放位置
export function PositionUpdate(data) {
    return request({
      url: '/api/services/app/MerchantPlacementPosition/Update',
      method: 'put',
      data
    })
}